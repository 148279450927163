import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as Actions from "../../Redux/Actions";

import "./filter.scss";
import { textToUrlSlug } from "../../Constants/Functions";

function FilterMobileComponent({ show, setShow, loading, brands }) {
  const history = useHistory();
  const { id, sizee } = useParams();

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  let sortBy = queryParameters.get("sort_by");

  const [selectedBrand, setSelectedBrand] = useState(
    brands.find((b) => b.main_category_id == id || textToUrlSlug(b.title) == id)
  );
  const [existingCollection, setExistingCollection] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const [selectedSize, setSelectedSize] = useState(sizee);

  useEffect(() => {
    if (id == 0) {
      setSelectedBrand(null);
      setSelectedCollection(null);
      return;
    }

    let brandFound = null;
    let collectionFound = null;

    for (let i = 0; i < brands.length; i++) {
      const brand = brands[i];

      const slug = brand.url.split("/").pop();

      if (slug == id || brand.main_category_id == id) {
        brandFound = brand;
        break;
      }

      for (let j = 0; j < brand.children.length; j++) {
        const collection = brand.children[j];

        const slug = collection.url.split("/").pop();

        if (slug == id || collection.collection_id == id) {
          collectionFound = collection;
          break;
        }
      }

      if (collectionFound) break;
    }

    if (brandFound) {
      setSelectedBrand(brandFound);
      setExistingCollection(null);
    } else if (collectionFound) {
      setExistingCollection(collectionFound);
      setSelectedBrand(null);
    } else {
      setSelectedBrand(null);
      setExistingCollection(null);
    }
  }, [brands, id]);

  useEffect(() => {
    setSelectedCollection(existingCollection);
  }, [existingCollection]);

  const renderSizes = useCallback(() => {
    let sizesToRender = [];

    if (selectedBrand) {
      sizesToRender = selectedBrand.sizes;
    } else {
      let collectionBrand = brands.find((b) =>
        b.children.find(
          (c) => c.collection_id == selectedCollection.collection_id
        )
      );
      sizesToRender = collectionBrand.sizes;
    }

    return sizesToRender?.map((size, index) => (
      <div
        key={`size-${size}-${index}`}
        onClick={() => setSelectedSize(size)}
        className={`btn ${
          selectedSize == size ? "btn-danger" : "btn-outline-secondary"
        } size`}
      >
        {size}
      </div>
    ));
  }, [brands, selectedBrand, selectedCollection, selectedSize]);

  const close = () => {
    setShow(false);
  };

  const onClickApplyFilter = () => {
    let url = "/collections/";

    if (selectedBrand) {
      const slug = selectedBrand.url.split("/").pop();
      url += `${slug}/`;
    } else if (selectedCollection) {
      const slug = selectedCollection.url.split("/").pop();
      url += `${slug}/`;
    } else {
      url += "0/";
    }

    if (selectedSize) {
      url += `size/${selectedSize}/`;
    }

    if (!!sortBy) {
      url += `?sort_by=${sortBy}`;
    }

    history.replace(url);
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="filterComponent_ComponentContainer">
      <div className="filterComponent_ComponentHeader text-center">
        <span className="filterComponent_closeBtn" onClick={close}>
          Close
        </span>
        <span>
          <h3>Filter</h3>
        </span>
        <span className="filterComponent_resetBtn">{"Reset"}</span>
      </div>
      <div className="filterComponent_headerDevider"></div>
      <div className="filterComponent_body">
        <div>
          <h4>Categories</h4>
        </div>
        {brands.map((brand, index) => (
          <div
            key={`brand-${brand.id}-${index}`}
            onClick={() => {
              setSelectedBrand(brand);
              setSelectedCollection(null);
              setSelectedSize(null);
            }}
            className={`btn btn-block btn-lg ${
              selectedBrand?.main_category_id == brand.main_category_id &&
              !selectedCollection
                ? "btn-danger"
                : "btn-outline-secondary"
            }`}
          >
            {brand.title}
          </div>
        ))}

        {!!existingCollection && (
          <div
            onClick={() => {
              setSelectedBrand(null);
              setSelectedCollection(existingCollection);
              setSelectedSize(null);
            }}
            className={`btn btn-block btn-lg ${
              selectedCollection && !selectedBrand
                ? "btn-danger"
                : "btn-outline-secondary"
            }`}
          >
            {existingCollection.title}
          </div>
        )}

        {(!!selectedBrand || !!selectedCollection) && (
          <div className="mt-4">
            <h4>Sizes</h4>

            <div className="size-selection-container">
              <div
                onClick={() => setSelectedSize(null)}
                className={`btn ${
                  selectedSize == null ? "btn-danger" : "btn-outline-secondary"
                } size`}
              >
                All
              </div>
              {renderSizes()}
            </div>
          </div>
        )}

        {(!!selectedBrand || !!selectedCollection) && (
          <div className="row">
            <div className="col">
              <button
                className={`btn btn-lg btn-block ${
                  loading ? "btn-default" : "btn-danger"
                } mt-4`}
                onClick={onClickApplyFilter}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={20} color="error" />
                ) : (
                  "Apply Filter"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.browse.showMobileFilter,
    loading: state.browse.loading,
    brands: state.products.brands.map((b) => ({
      ...b,
      sizes: state.browse.categories.find((c) => c.id == b.main_category_id)
        ?.sizes,
    })),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShow: (data) => {
      dispatch({ type: Actions.SET_SHOW_MOBILE_FILTER, payload: data });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterMobileComponent);
