import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { CustomSearchResultItem } from "../search-item/CustomSearchResultItem";
import { makingValidName } from "../../Constants/Functions";
import { CloseSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.png";

import "./Searchbar.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavSearchBar = ({ allProducts, allBrands }) => {
  const newhistory = useHistory();

  const [inputValue, setInputValue] = useState("");
  const [filterProducts, setFilterProducts] = useState([]);
  const [filterBrands, setfilterBrands] = useState([]);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchbar, setSearchbar] = useState(false);

  useEffect(() => {
    setItems([...filterProducts.slice(0, 20), ...filterBrands]);
  }, [filterProducts, filterBrands]);

  const handleChange = (e) => {
    if (e.target.value.length === 0) {
      setSearchbar(false);
    } else {
      setSearchbar(true);
    }
    setInputValue(e.target.value);
    var enterdValue = makingValidName(`${e.target.value}`);
    setFilterProducts(
      allProducts?.filter(
        (dat, index) =>
          makingValidName(`${dat.name}`)?.includes(enterdValue) ||
          makingValidName(`${dat.name}`) === enterdValue ||
          makingValidName(`${dat.colorway}`)?.includes(enterdValue) ||
          makingValidName(`${dat.colorway}`) === enterdValue ||
          makingValidName(`${dat.sku_number}`)?.includes(enterdValue) ||
          makingValidName(`${dat.sku_number}`) === enterdValue
      )
    );
    setfilterBrands(
      allBrands?.filter(
        (item, index) =>
          makingValidName(`${item.title}`)?.includes(enterdValue) ||
          makingValidName(`${item.title}`) === enterdValue
      )
    );
  };

  const fetchMoreData = () => {
    if (items.length >= filterProducts.length) {
      setHasMore(false);
    }

    setTimeout(() => {
      setItems((prevItems) => [
        ...prevItems,
        ...filterProducts.slice(
          prevItems.length - filterBrands.length,
          prevItems.length + 20
        ),
      ]);
    }, 500);
  };

  const toLink = (link) => {
    newhistory.push(link);
    setInputValue("");
    setSearchbar(false);
  };

  const renderItem = (item, index) => {
    const key = `CustomSearchResultItem-filterProducts-${
      item.shoe_id ? `shoe-${item.shoe_id}` : `brand-${index}`
    }`;
    const imgUrl = "children" in item ? item.imageURL : item.cover_image;
    const title = "children" in item ? item.title : item.name;
    const description =
      "children" in item ? item.description : `Sku Number: ${item?.sku_number}`;
    const link = item.shoe_id
      ? `/${makingValidName(item.name + "")}_id_${makingValidName(
          item.shoe_id + ""
        )}`
      : `/browse/${item.collection_id}/`;

    return (
      <NavDropdown.Item key={key}>
        <CustomSearchResultItem
          imgUrl={imgUrl}
          title={title}
          description={description}
          toLink={() => toLink(link)}
        />
      </NavDropdown.Item>
    );
  };

  return (
    <div className="searchContainerCont">
      <div className="CustomSearchContainer" style={{ margin: "0px" }}>
        <img
          src={searchIcon}
          style={{
            width: "20px",
            marginLeft: "15px",
          }}
          alt="Search"
        />
        <input
          className="searchInput"
          name="input"
          onChange={handleChange}
          value={inputValue}
          placeholder="Search for brands, colors etc"
        />
        {inputValue.length > 0 && (
          <button
            onClick={() => {
              setInputValue("");
              setSearchbar(false);
            }}
            className="closeBtn"
          >
            <CloseSharp />
          </button>
        )}
      </div>
      <NavDropdown
        title=""
        id="basic-nav-dropdown"
        className="navs bg-white search-dropdown"
        show={searchbar}
      >
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          height={400}
          loader={
            <NavDropdown.Item disabled>
              <div className="d-flex justify-content-center bg-white align-items-center py-4">
                <FontAwesomeIcon icon={["fa", "circle-notch"]} spin />
              </div>
            </NavDropdown.Item>
          }
        >
          {items.map((item, index) => renderItem(item, index))}
        </InfiniteScroll>
      </NavDropdown>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allProducts: state.products.products,
    allBrands: state.products.brands,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavSearchBar));
