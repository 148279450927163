import axios from "axios";
import { BASE_URL } from "../Constants/Global";

export const getRequest = ({ url, token = null, source = null }) => {
  return new Promise((resolve, reject) => {
    let headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    if (source) {
      headers.cancelToken = source.token;
    }

    axios
      .get(url, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postRequest = ({ url, data, token = null }) => {
  // console.log("=== postRequest:");
  // console.log("URL:", url);
  // console.log("DATA:", JSON.stringify(data, null, 2));
  return new Promise((resolve, reject) => {
    let headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .post(url, data, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error("POST REQUEST:", error);
        if ("response" in error) {
          reject(error);
        } else {
          reject(error.response);
        }
      });
  });
};

export const patchRequest = ({ url, data, token = null }) => {
  // console.log("=== patchRequest:");
  // console.log("URL:", url);
  // console.log("DATA:", JSON.stringify(data, null, 2));
  return new Promise((resolve, reject) => {
    let headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .patch(url, data, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        if ("response" in error) {
          reject(error);
        } else {
          reject(error.response);
        }
      });
  });
};

export const putRequest = ({ url, data, token = null }) => {
  // console.log("=== putRequest:");
  // console.log("URL:", url);
  // console.log("DATA:", JSON.stringify(data, null, 2));
  return new Promise((resolve, reject) => {
    let headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .put(url, data, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        if ("response" in error) {
          reject(error);
        } else {
          reject(error.response);
        }
      });
  });
};

export const deleteRequest = ({ url, token = null }) => {
  // console.log("=== deleteRequest:");
  // console.log("URL:", url);

  return new Promise((resolve, reject) => {
    let headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .delete(url, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if ("response" in error) {
          reject(error);
        } else {
          reject(error.response);
        }
      });
  });
};

export const getCollections = () => {
  const url = `${BASE_URL}collections`;

  return getRequest({ url });
};

export const getCollectionsId = (id) => {
  const url = `${BASE_URL}collections/${id}`;

  return getRequest({ url });
};

export const getCollectionById = (id) => {
  const url = `${BASE_URL}collections/${id}`;

  return getRequest({ url });
};

export const getCollectionsDropdown = () => {
  // const url = `https://thriller.cloudious.net/collections/dropdown`;
  const url = `${BASE_URL}collections/dropdown`;

  return getRequest({ url });
};

export const getShoes = (controller = null) => {
  const url = `${BASE_URL}shoes`;
  return getRequest({ url, controller });
};

/**
 *
 * @param {
 *  id ------------- Main categories id (left sidebar),
 *  sort_by -------- "asc" or "desc",
 *  tag ------------ "Popular", "New", "Featured",
 *  collection_id -- browse dropdown option id,
 *  size ----------- from left sidebar
 *  page ----------- for Pagination
 * } params
 * @param AbortController controller
 * @returns
 */
export const getShoesByParams = (params = {}, source = null) => {
  let url = `${BASE_URL}shoes`;

  if (Object.keys(params).length) {
    url += `/GetShoes?${new URLSearchParams(params).toString()}`;
  }

  return getRequest({ url, source });
};

export const getShoesByCollection = (id) => {
  const url = `${BASE_URL}shoes/collections/${id}`;

  return getRequest({ url });
};

export const getShoeImages = (id) => {
  const url = `${BASE_URL}shoesImages/${id}`;

  return getRequest({ url });
};
export const getShoeBannerType = (id) => {
  const url = `${BASE_URL}shoes/bannerType/${id}`;

  return getRequest({ url });
};

export const getShoeCost = (id) => {
  const url = `${BASE_URL}shoes/shoeCost/${id}`;

  return getRequest({ url });
};

export const getShoesByCategory = ({ id }) => {
  const url = `${BASE_URL}shoes/ByCategory/${id}`;

  return getRequest({ url });
};

export const getShoesByCategorySize = ({ id, size }) => {
  const url = `${BASE_URL}shoes/ByCategory/${id}/${size}`;

  return getRequest({ url });
};

export const getShoesByTag = (tag) => {
  const url = `${BASE_URL}shoes/getByTag/${tag}`;

  return getRequest({ url });
};

export const getCollectionsByTitle = (title) => {
  const url = `${BASE_URL}collections/getByTitle/${title}`;

  return getRequest({ url });
};

export const getShoesByName = (keyword, controller = null) => {
  const url = `${BASE_URL}shoes/getByName/${keyword}`;

  return getRequest({ url, controller });
};

export const getListingLowest = (id, size) => {
  const url = `${BASE_URL}listing/lowest/${id}/${size}`;

  return getRequest({ url });
};

export const getBanners = () => {
  // const url = "https://thriller.cloudious.net/banners";
  const url = `${BASE_URL}banners`;

  return getRequest({ url });
};

export const getOffersCurrent = (user_id) => {
  const url = `${BASE_URL}offers/current/${user_id}`;

  return getRequest({ url });
};

export const getOffers = (shoe_id, size, highest_offer) => {
  const url = `${BASE_URL}offers/getOffer/${shoe_id}/${size}/${highest_offer}`;

  return getRequest({ url });
};

export const getFav = (user_id) => {
  const url = `${BASE_URL}fav/${user_id}`;

  return getRequest({ url });
};

export const postFav = (data) => {
  const url = `${BASE_URL}fav`;

  return postRequest({ url, data });
};

export const getRegistrations = (user_id) => {
  const url = `${BASE_URL}registrations/${user_id}`;

  return getRequest({ url });
};

export const getSellers = (user_id) => {
  const url = `${BASE_URL}sellers/${user_id}`;

  return getRequest({ url });
};

export const getPayout = (user_id) => {
  const url = `${BASE_URL}payout/${user_id}`;

  return getRequest({ url });
};

export const registrationsLogin = (data) => {
  const url = `${BASE_URL}registrations/login`;

  return postRequest({ url, data });
};

export const registrations = (data) => {
  const url = `${BASE_URL}registrations/`;

  return postRequest({ url, data });
};

export const signup = (data) => {
  const url = `${BASE_URL}signup`;

  return postRequest({ url, data });
};

export const getSettings = () => {
  const url = `${BASE_URL}settings`;

  return getRequest({ url });
};

export const getListingBuynow = (id, size) => {
  const url = `${BASE_URL}listing/buynow/${id}/${size}`;

  return getRequest({ url });
};

export const getShippings = (user_id) => {
  const url = `${BASE_URL}shippings/${user_id}`;

  return getRequest({ url });
};

export const getSearchesRecent = (user_id) => {
  const url = `${BASE_URL}searches/recent/${user_id}/5`;

  return getRequest({ url });
};

export const postSearches = (data) => {
  const url = `${BASE_URL}searches`;

  return postRequest({ url, data });
};

export const getSearchesPopular = () => {
  const url = `${BASE_URL}searches/popular/5`;

  return getRequest({ url });
};

export const stripeCapture = (data) => {
  const url = `${BASE_URL}stripe/capture`;

  return postRequest({ url, data });
};

export const getOrders = (orderId) => {
  const url = `${BASE_URL}orders/${orderId}`;

  return postRequest({ url });
};

export const postOrders = (data) => {
  const url = `${BASE_URL}orders`;

  return postRequest({ url, data });
};

export const getListingCurrent = (user_id) => {
  const url = `${BASE_URL}listing/current/${user_id}`;

  return getRequest({ url });
};

export const getOrderSellerHistory = (user_id) => {
  const url = `${BASE_URL}orders/sellerHistory/${user_id}`;

  return getRequest({ url });
};

export const getOrderSellerPending = (user_id) => {
  const url = `${BASE_URL}orders/sellerPending/${user_id}`;

  return getRequest({ url });
};

export const postQuiqupOndemand = (data) => {
  const url = `${BASE_URL}quiqup/ondemand`;

  return postRequest({ url, data });
};

export const getStylesGallerySelected = (shoe_id) => {
  const url = `${BASE_URL}styles/galleryselected/${shoe_id}`;

  return getRequest({ url });
};

export const getCategories = () => {
  const url = `${BASE_URL}categories`;

  return getRequest({ url });
};
