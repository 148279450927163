import React from "react";
import "./NewCustomRecentcs.scss";
import { makingValidName } from "../../Constants/Functions";
import { useHistory } from "react-router-dom";
import cardImg6 from "../../temporary-data/6.png";
import { KeyboardArrowRight } from "@material-ui/icons";

const MultiBrandsRecentcs = ({
  allProducts = [],
  allBrands = [],
  tagsArry,
}) => {
  const history = useHistory();

  let shoesBrand = allBrands.find((brand) => brand.title == "Shoes");

  console.log("shoesBrand:", shoesBrand);

  let topTen = tagsArry.map((tag) => ({
    brandName: `${tag} Top 10`,
    data: allProducts
      .filter(
        (product) =>
          product.tag.includes(tag) ||
          makingValidName(`${product?.name}`).includes(makingValidName(tag)) ||
          product?.collection_id ===
            shoesBrand?.children.find((brand) =>
              makingValidName(brand.title).includes(makingValidName(tag))
            )?.collection_id
      )
      .slice(0, 10),
  }));
  console.log("topTen:", topTen);

  return (
    <>
      <div className="row flex-row">
        <div className="col-md-10 col-sm-12 mt-5 mb-3">
          <h1 className="slidersHeading">Thriller Top 10</h1>
        </div>
        <div className="col-md-2 col-sm-12">
          <button
            onClick={() => console.log("don't know where to go")}
            className="nextButton hideInMobile"
          >
            <span className="mr-1">SELL ALL</span>
            <KeyboardArrowRight />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="productShowDivBrands">
          {topTen.map((dat, index) => (
            <div className="columnProductContainer" key={index}>
              <h5 className="my-3 mx-1 text-center slidersHeading">
                {dat.brandName == "Travis Scot Top 10"
                  ? "Travis Scott Top 10"
                  : dat.brandName}
              </h5>
              <div className="cardContainers">
                {dat?.data?.map((dam, ind) => {
                  const newname = makingValidName(`${dam.name}`);
                  const newshoeid = makingValidName(`${dam.shoe_id}`);
                  return (
                    <div
                      onClick={() =>
                        history.push(`/${newname}_id_${newshoeid}`)
                      }
                      key={ind}
                      className="smallCardCont d-flex align-items-center justify-content-center flex-row"
                    >
                      <span className="spanText">{ind + 1}</span>
                      <img
                        src={dam.cover_image ? dam.cover_image : cardImg6}
                        alt={dam.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MultiBrandsRecentcs;
