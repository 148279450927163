import React, { useState, useEffect, useCallback, useRef } from "react";
import FilterSection from "../../components/filter-section/FilterSection";
import FilterSort from "../../components/filter-sortbar/FilterSort";
import ShoeCard from "../../components/shoe-card/ShoeCard";
import ShopBanner from "../../components/shop-all-bannar/ShopBanner";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

import MobileFilterButton from "../../components/filterBtn/filterBtn";
import FilterMobileComponent from "../../components/filterMobileComponent/FilterMobileComponent";

import { useParams } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";
import { connect } from "react-redux";
import * as Actions from "../../Redux/Actions";

import { getShoesByParams } from "../../utils/api";

import {
  removeNullKeys,
  textToUrlSlug,
  titleCase,
} from "../../Constants/Functions";

import "./browse.scss";

let source = axios.CancelToken.source();

const Browse = ({ state, collections, brands, setLoading, setProducts }) => {
  console.log("BRANDS:", brands);
  
  const location = useLocation();
  const params = useParams();

  const queryParameters = new URLSearchParams(location.search);
  let sortBy = queryParameters.get("sort_by");

  const { id, sizee, keyword } = params;
  if (!sortBy) {
    sortBy = "desc";
  }

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [metaData, setMetaData] = useState({
    title: "",
    description: "",
    keywords: "",
    count: "",
  });
  const [queryParams, setQueryParams] = useState({
    id: null, // ................. Main categories id (left sidebar),
    sort_by: sortBy, // ....... "asc" or "desc",
    tag: null, // ............. "Popular", "New", "Featured",
    collection_id: null, // ... browse dropdown option id,
    size: null, // ............ from left sidebar
    limit: 24, //............... items per page
  });

  const { loading, showMobileFilter, products } = state;

  const fetchShoes = useCallback(() => {
    if (source) {
      source.cancel("Operation canceled for new call");
    }

    source = axios.CancelToken.source();

    // console.log("fetchShoes:", { ...queryParams, page });
    let filteredParams = removeNullKeys(queryParams);
    // console.log("fetchShoes:", { ...filteredParams, page });

    getShoesByParams({ ...filteredParams, page }, source)
      .then((response) => {
        if (response.length === 0) {
          setHasMore(false); // No more shoes to load
        } else {
          setProducts([...products, ...response]);
          setPage(page + 1); // Increment page number for next fetch
        }
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
        console.error(error);
      });
  }, [page, products, setLoading, setProducts, queryParams]);

  useEffect(() => {
    if (hasMore) {
      fetchShoes();
    }
  }, [queryParams, hasMore]);

  // reset products if any one of these query params change
  useEffect(() => {
    setLoading(true);
    setProducts([]);
    setPage(1);
    setHasMore(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sizee, keyword, sortBy, setProducts, setLoading]);

  // set Query Params
  useEffect(() => {
    if (id == 0) {
      setQueryParams({
        limit: 24,
      });
      return;
    }

    let newQueryParams = {
      limit: 24,
    };

    if (keyword != null) {
      newQueryParams.keyword = keyword;
    }

    if (id) {
      let brandFound = null;
      let collectionFound = null;

      // find the brand or collection from the id
      for (let i = 0; i < brands.length; i++) {
        const brand = brands[i];

        const slug = brand.url.split("/").pop();

        if (slug == id || brand.main_category_id == id) {
          brandFound = brand;
          break;
        }

        for (let j = 0; j < brand.children.length; j++) {
          const collection = brand.children[j];
          const slug = collection.url.split("/").pop();

          if (slug == id || collection.collection_id == id) {
            collectionFound = collection;
            break;
          }
        }

        if (collectionFound) break;
      }

      if (brandFound) {
        newQueryParams.id = brandFound.main_category_id;
      } else if (collectionFound) {
        newQueryParams.collection_id = collectionFound.collection_id;
      } else {
        newQueryParams.tag = titleCase(id.replace(/-/g, " "));
      }

      if (sizee != null) {
        newQueryParams.size = sizee;
      }
    } else {
      delete newQueryParams.id;
      delete newQueryParams.sizee;
    }

    if (sortBy?.trim().length) {
      newQueryParams.sort_by = sortBy;
    }

    setQueryParams(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sizee, keyword, sortBy]);

  useEffect(() => {
    const metaJson = [
      {
        url: "https://thrillerme.com/collections/jordan",
        title:
          "Shop Jordan Shoe Online for Men & Women in Dubai | Thriller UAE",
        count: 64,
        description:
          "Elevate your style with Air Jordan shoes from Thrillerme-UAE. Find the perfect jordan sneakers and apparel in dubai to make a statement on and off the court.",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/nike",
        title:
          "Buy Nike Shoes, Air Jordan, Airforce Online in Dubai | Thriller UAE",
        count: 67,
        description:
          "Stay ahead of the Nike game with Thrillerme UAE. Explore a wide range of air jordan sneakers, accessories for men, women, and kids.",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/yeezy",
        title: "Shop Adidas Yeezy Online - Sneakers in Dubai - Thriller UAE",
        count: 59,
        description:
          "Discover the hottest Adidas Yeezy styles at Thriller UAE. Get your hands on the most sought-after yeezys sneakers in the UAE. Don't miss out on the hype!",
        keywords: "Yeezy Dubai, Adidas Yeezy, Yeezy Shoes Online",
      },
      {
        url: "https://thrillerme.com/collections/oncloud-running-",
        title:
          "Buy On Running Cloud Collections, Best Running Shoes Online - Thriller UAE",
        count: 74,
        description:
          "Unleash the thrill of on- running with Thrillerme.com. From top-notch gear recommendations to race updates, check our on running cloud shoes and shop online!",
        keywords:
          'Travis Scott Sneakers, Nike Travis Scott, "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai"',
      },
      {
        url: "https://thrillerme.com/collections/travis-scott",
        title:
          "Buy Latest Travis Scott Sneakers - Air Jordan Collections - Thriller UAE",
        count: 72,
        description:
          "Buy Travis Scott sneakers on Thrillerme website in Dubai, UAE.Explore latest arrivals of Travis Scott t-shoes today. Shop online Air Jordan Travis Scott collabration shoes.",
        keywords:
          'Travis Scott Sneakers, Nike Travis Scott, "Air Jordan,\nAirJordan Shoes, Nike Shoes, Jordan Sneakers',
      },
      {
        url: "https://thrillerme.com/collections/jordan-1-lows",
        title:
          "Buy Air Jordan 1 Low - Latest Air Jordan Sneakers Online - Thriller UAE",
        count: 71,
        description:
          "Add the versatile Air Jordan 1 Mid to your collection. Explore a wide range of styles for men, women enhancing your rotation with Michael Jordan’s iconic shoe.",
        keywords:
          '"Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai"',
      },
      {
        url: "https://thrillerme.com/collections/swatch-x-omega",
        title:
          "Buy Swatch x Omega Moonswatch Online - Swatch Watches - Thriller UAE",
        count: 68,
        description:
          "The Swatch x Omega Mission to Mercury Moonswatch sports both logos and a monochromatic gray and black design, making it a standout in the collection.",
        keywords: "Swatch x Omega, Swatch Watches, Moonwatch",
      },
      {
        url: "https://thrillerme.com/collections/display-",
        title: "Display Cases, Bags & Storage - Dubai - Thriller UAE",
        count: 53,
        description:
          "Explore the latest in display cases technology at Thrillerme UAE. Find top-quality products and innovative solutions for all your display needs.",
        keywords:
          "Sneakers, Online, Bags, Accessories, Streetwear\nAir Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/crep-protect-",
        title:
          "Crep Protect: Ultimate Sneaker Protection and Care - Thriller UAE",
        count: 65,
        description:
          "Keep your sneakers crease-free with our reusable Crease Protectors. Exclusive gel-tech cushioning ensures supreme comfort while protecting against toe box creases.",
        keywords:
          '"Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai"',
      },
      {
        url: "https://thrillerme.com/collections/women's-shoes",
        title:
          "Buy Women Shoes Online - Air Jordan, Yeezy, New Balance - Thriller UAE",
        count: 70,
        description:
          "Check out our exclusive women shoes collection at Thriller UAE. Shop the best footwear brand, Nike, Air Jordan, Adidas, Yeezys. New Balance and On Running shoes.",
        keywords:
          '"Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai, Yeezy Online',
      },
      {
        url: "https://thrillerme.com/collections/kids-collection",
        title: "Shop Kids Collection for Kids Online in UAE - Thriller UAE",
        count: 58,
        description:
          "Shop the best Kids Collection at Thrillerme UAE. Browse a wide selection of fashionable and high-quality clothing for children. Dress your kids in style!",
        keywords:
          "Air Jordan Kids, Kids Sneakers, Air Jordan, \nAir Jordan Shoes, Nike Shoes,",
      },
      {
        url: "https://thrillerme.com/collections/herm%C3%A8s-",
        title:
          "Buy Hermes Chypre Sandals - Shop Hermes Online in Dubai - Thriller UAE",
        count: 70,
        description:
          "Discover the sleek design of Hermes Chypre sandals, blending comfort and style. Perfect for all seasons, these Hermes sandals offer modern elegance.",
        keywords: "Hermes, Hermes Sandals, Hermes Online,\nHermes Dubai",
      },
      {
        url: "https://thrillerme.com/collections/herm%C3%A8s-sandals-(women)",
        title: "Buy Hermes Shoes, Sandals & Slides in Dubai - Thriller UAE",
        count: 58,
        description:
          "Step into luxury with Hermes sandals for women at ThrillerMe. Shop the exclusive collection online in UAE and elevate your style with timeless elegance.",
        keywords: "Hermes, Hermes Sandals, Hermes Online,\nHermes Dubai",
      },
      {
        url: "https://thrillerme.com/collections/off-white",
        title: "Buy off white shoes for men and women in UAE - Thriller UAE",
        count: 59,
        description:
          "Explore and purchase our wide selection of OFF-WHITE Men's Shoes from top online retailers. Refresh your wardrobe, no matter your style, by shopping on Thrillerme",
        keywords:
          "Off White Jordan, Nike Shoes for Men, Nike Shoes\nfor Women, Off White Dubai",
      },
      {
        url: "https://thrillerme.com/collections/supreme",
        title: "Buy Buy Supreme Running Shoes For Men - Thriller UAE",
        count: 52,
        description:
          "Explore and buy our extensive range of Supreme Shoes from the best online shops. Elevate your look with any style by shopping our collection today!",
        keywords: "On Running, On Running Cloud, Cloud 5 Shoes,",
      },
      {
        url: "https://thrillerme.com/collections/new-balance",
        title: "Shop New Balance for Men & Women - Thriller UAE",
        count: 47,
        description:
          "Shop a wide range of New Balance shoes at ThrillerMe, offering the perfect blend of timeless style and superior quality. Discover your ideal pair today!",
        keywords:
          "New Balance, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/yeezy-slides",
        title: "Buy Yeezy Slides Shoes online in Dubai - Thriller UAE",
        count: 53,
        description:
          "Discover and purchase our wide selection of Yeezy Slides from leading online retailers. Enhance your style and comfort with our exclusive collection. Shop today!",
        keywords: "Yeezy Dubai, Adidas Yeezy, Yeezy Shoes Online",
      },
      {
        url: "https://thrillerme.com/collections/dunk",
        title: "Shop Nike Dunk Collection online in Dubai - Thriller UAE",
        count: 56,
        description:
          "Shop the Nike Dunk Collection online in Dubai. Discover a range of stylish and trendy Nike Dunk shoes, available for effortless shopping and delivery.",
        keywords:
          "Nike Dunk, Air Jordan, Air Jordan Shoes, \nNike Shoes, Jordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/jordan-1-lows",
        title: "Shop the iconic Jordan 1 Low in Dubai - Thriller UAE",
        count: 52,
        description:
          "Buy the iconic Jordan 1 Low in the UAE for timeless design and unmatched style. Shop now at Thriller and elevate your sneaker collection with this legendary footwear!",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/the-ten-",
        title: 'Nike x "The Ten": Iconic Sneakers - Shop Thriller UAE',
        count: 53,
        description:
          'Discover the iconic Nike x "The Ten" collection at Thriller UAE. Shop now for these legendary sneakers and elevate your style with unparalleled design and quality',
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/comme-des-gar%C3%A7ons-",
        title: "Shop Comme des Garçons Shoes for Women - Thriller UAE",
        count: 53,
        description:
          "Shop the latest Comme des Garçons shoes for women in UAE at Thriller. Discover stylish and unique footwear that elevates your fashion game. Shop now!",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/sacai",
        title: "Shop SACAI Shoes online - sneakers in Dubai - Thriller UAE",
        count: 59,
        description:
          "Shop SACAI shoes online at Thriller UAE. Discover unique sneakers in Dubai and elevate your style with cutting-edge designs and premium quality. Shop now!",
        keywords:
          "Sacai Shoes, Dubai Sneakers. Air Jordan,\nAir Jordan Shoes, Nike Shoes,",
      },
      {
        url: "https://thrillerme.com/collections/fear-of-god-",
        title: "Shop Fear of God Essentials online in UAE - Thrillerme",
        count: 54,
        description:
          "Shop the Fear of God Essentials online at Thriller UAE. Explore our exclusive collection of high-end streetwear and fashion essentials. Shop now!",
        keywords:
          "Fear of God Essentials, FOG T Shirts, FOG\nHoodies, Streetwear",
      },
      {
        url: "https://thrillerme.com/collections/skate-",
        title: "Buy Skate Shoes Online in Dubai - Thrillerme UAE",
        count: 49,
        description:
          "Find top-quality skate shoes online in Dubai at ThrillerMe UAE. Shop our exclusive collection for unbeatable style and performance, with convenient delivery",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/cosy-comfort-",
        title: "Cosy Comfortable Shoes for Men and Women - Thriller UAE",
        count: 56,
        description:
          "Step into ultimate comfort and style with Thriller online in UAE. Find cosy, comfortable shoes for men and women, perfect for any occasion. Shop today!",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/pharrel-",
        title: "Ferragamo Pharrel Sneakers online in Dubai - Thriller UAE",
        count: 58,
        description:
          "Shop Ferragamo Pharrell Sneakers online at Thriller UAE. Discover premium sneakers in Dubai and elevate your style with top-notch footwear. Shop now!",
        keywords:
          "Air Jordan, Air Jordan Shoes, Nike Shoes, \nJordan Sneakers, Air Jordan Dubai",
      },
      {
        url: "https://thrillerme.com/collections/supreme-apparel",
        title: "Supreme Apparel - Hoodies & Sweatshirt Online - Thriller UAE",
        count: 60,
        description:
          "Step into ultimate comfort and style Supreme Hoodies with Thriller. Find cosy, comfortable \ntshirts, socks and shots for men and women. Shop online today!",
        keywords:
          "Fear of God Essentials, FOG T Shirts, FOG\nHoodies, Streetwear",
      },
      {
        url: "https://thrillerme.com/collections/essentials-",
        title:
          "Fear of God Essentials - Hoodies, Tshirts, Pants Online - Thriller UAE",
        count: 70,
        description:
          "Shop new season FEAR OF GOD ESSENTIALS clothing for men in Dubai. Choose iconic Fear\nof God Essentials Hoodies, Pants online at Thriller Me.",
        keywords:
          "Fear of God Essentials, FOG T Shirts, FOG\nHoodies, Streetwear",
      },
      {
        url: "https://thrillerme.com/collections/nike-apparel-",
        title:
          "Nike Apparel Online - Nike Streetwear & Jackets Dubai - Thriller UAE",
        count: 68,
        description:
          "Shop now latest Nike Apparel Online in Dubai at Thriller Me. Choose iconic Nike Streetwear & Jackets for men, Women online at Thriller Me. Buy Nike Sneakers Now!",
        keywords:
          "Nike Apparel, premium clothing, outerwear, streetwears, tshirt, logo printed hoodies",
      },
      {
        url: "https://thrillerme.com/collections/tshirts",
        title:
          "T-Shirts Online - Travis Scott, Supreme, Palm Angel, FOG In Dubai - Thriller",
        count: 76,
        description:
          "Shop now latest Tshirts of Supreme, Fear of God Essentials, Supreme and Travis Scott Online in Dubai at Thriller. Get the collection of Streetwear & Hoodies for men, Women.",
        keywords:
          "Palm Angels, FOG Essentials, premium clothing, outerwear, streetwears, tshirt, logo printed hoodies",
      },
      {
        url: "https://thrillerme.com/collections/palm-angels-",
        title:
          "Palm Angels Logo Print T-shirts In Dubai - Best Streetwear Online - Thriller",
        count: 76,
        description:
          "Shop now latest Palm Angels Logo Print T-shirts Online in Dubai at Thriller. Get the collection best of Headed Logo Tshirts for men and Women.",
        keywords:
          "Palm Angels, FOG Essentials, premium clothing, outerwear, streetwears, tshirt, logo printed hoodies",
      },
      {
        url: "https://thrillerme.com/collections/hoodies",
        title:
          "Sweatshirts & Hoodies - Fear of GOD Essentials & Palm Angels in Dubai",
        count: 69,
        description:
          "Shop now latest Fear of GOD Essentials and Palm Angels Logo Printed Hoodies Online in Dubai at Thriller. Get the collection best of Logo hoodies for men and Women.",
        keywords:
          "Palm Angels, FOG Essentials, premium clothing, outerwear, streetwears, tshirt, logo printed hoodies",
      },
      {
        url: "https://thrillerme.com/collections/ovo",
        title:
          "OVO Designs Premium Clothing, Outerwear and Accessories Online - Thriller",
        count: 75,
        description:
          "OVO designs and manufactures premium clothing, outerwear, accessories, and home goods inspired by its Canadian roots. Shop online at Thriller store in Dubai.",
        keywords:
          "premium clothing, outerwear, streetwears, tshirt, logo printed hoodies",
      },
      {
        url: "https://thrillerme.com/collections/kaws",
        title:
          "KAWS Collectable Family Figures Brown/Blue/White Toys - Thriller ME Dubai",
        count: 73,
        description:
          "Find our KAWS edit on Thriller Online. Look to collectable toys, jigsaw puzzles and KAWS logo clothing. Enjoy fast delivery in Dubai.",
        keywords:
          "KAWS Collectables, KAWS Figure, KAWS Toys online in Dubai, KAWS Dubai",
      },
    ];

    const currentPath = `https://thrillerme.com${location.pathname}`;
    const currentMeta = metaJson.find((item) => item.url === currentPath);

    if (currentMeta) {
      setMetaData({
        title: currentMeta.title,
        description: currentMeta.description, // Note: there's a typo in the JSON key
        keywords: currentMeta.keywords,
        count: currentMeta.count.toString(),
      });
    } else {
      setMetaData({
        title:
          "UAE Leading Platform for Sneakers: Buy And Sell Sneakers, Streetwear and Accessories.",
        description:
          "Thriller is the Middle East platform to buy and sell the most in-demand footwear, streetwear and accessories from Nike, Jordan, Adidas, Supreme and more.",
        keywords: "",
        count: "",
      });
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="count" content={metaData.count} />
      </Helmet>

      <div className="primary-container">
        <div className="banner-filter-container">
          <ShopBanner />
          <FilterSort />
        </div>
        <FilterMobileComponent />
        {!showMobileFilter && (
          <>
            <MobileFilterButton />

            <div className="items-container">
              <FilterSection />

              <div style={{ width: "100%" }}>
                <div id="shoeCardContainer">
                  {!loading && products.length === 0 && (
                    <div className="empty-result">
                      <h4>
                        <strong>
                          Nothing to see here! Please change your filters
                        </strong>
                      </h4>
                    </div>
                  )}

                  <InfiniteScroll
                    dataLength={products.length}
                    next={fetchShoes}
                    hasMore={hasMore}
                    loader={
                      <div style={{ width: "100%" }}>
                        <h4 className="text-center py-3">
                          <strong>Loading...</strong>
                        </h4>
                      </div>
                    }
                    className="shoe-card-container"
                  >
                    {products.map((elem) => (
                      <ShoeCard
                        key={elem.shoe_id}
                        id={elem.shoe_id}
                        img={elem.cover_image}
                        name={elem.name}
                        lowestAsk={elem.lowestAsk}
                        newprice={elem.price}
                        date={elem.release_date}
                        tag={elem.tag}
                        showCount={products.length}
                        fulldata={elem}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </>
        )}

        <Links />
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.browse,
    collections: state.products.collections,
    brands: state.products.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (data) => {
      dispatch({ type: Actions.SET_LOADING, payload: data });
    },
    setProducts: (data) => {
      dispatch({ type: Actions.SET_SHOP_PRODUCTS, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Browse);
