import React, { useState, useEffect } from "react";
import "./search-result.styles.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchItem from "../search-item/search-item.component";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchResult = ({
  SearchResult,
  setSearchbar,
  setInputValue,
  searchbar,
}) => {
  const location = useLocation();

  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(SearchResult.slice(0, 20));
  }, [SearchResult]);

  const fetchMoreData = () => {
    if (items.length >= SearchResult.length) {
      setHasMore(false);
    }

    setTimeout(() => {
      setItems((prevItems) => [
        ...prevItems,
        ...SearchResult.slice(prevItems.length, prevItems.length + 20),
      ]);
    }, 10500);
  };

  return (
    <div className="searchManDiv" id="sidebarSearchMainDiv">
      {SearchResult && (
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          scrollableTarget="sidebarSearchMainDiv"
          loader={
            <div className="d-flex justify-content-center bg-white align-items-center py-4">
              <FontAwesomeIcon icon={["fa", "circle-notch"]} spin />
            </div>
          }
        >
          {items.map((val, ind) => (
            <SearchItem
              shoe={val}
              key={val.shoe_id}
              setSearchbar={setSearchbar}
              setInputValue={setInputValue}
              searchbar={searchbar}
              linkCheck={location.pathname !== "/favourites-section"}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default SearchResult;
