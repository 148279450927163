import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";

import { QuestionSign } from "../../components/recent/NewCustomRecentcs";
import tabbyIcon from "../../assets/product/tabbyIcon.svg";

import "./PlaceBidBuyNow.scss";

export default function PlaceBidBuyNow({
  loadingSizes,
  showPlaceBidModal,
  setShowPlaceBidModal,
  GoToBuy,
  GoToBid,
  buyBtnPrice,
}) {
  const [shouldGotoPlaceBid, setShouldGotoPlaceBid] = useState(false);

  useEffect(() => {
    if (showPlaceBidModal) {
      setShouldGotoPlaceBid(true);
    }
  }, [showPlaceBidModal]);

  const onClickPlaceBid = () => {
    if (shouldGotoPlaceBid) {
      GoToBid();
    } else {
      setShowPlaceBidModal(true);
    }
  };

  return (
    <>
      <div className="d-flex gap-2 px-0 mb-2 place-bid-buy-now">
        <div style={{ flex: 1 }}>
          <button
            className="btn btn-outline-dark btn-block"
            onClick={onClickPlaceBid}
            disabled={loadingSizes}
          >
            Place Bid{" "}
            {loadingSizes && <CircularProgress size={12} color="white" />}
          </button>
        </div>

        <div style={{ flex: 1 }}>
          <button
            className="btn btn-danger btn-block"
            onClick={GoToBuy}
            disabled={loadingSizes}
          >
            {loadingSizes ? (
              <CircularProgress size={12} color="white" className="ms-2" />
            ) : buyBtnPrice > 0 ? (
              <>
                Buy from <small>AED</small> {parseInt(buyBtnPrice)}+
              </>
            ) : (
              <>Buy</>
            )}
          </button>
        </div>
      </div>

      <div className="text-center py-2 pt-3">
        Starting at AED{buyBtnPrice ? buyBtnPrice / 4 : "---"}/mo with{" "}
        <img src={tabbyIcon} style={{ display: "inline" }} alt="tabbyIcon" />
        . <QuestionSign />
      </div>
    </>
  );
}
